<template>
  <div class="cart-item">
    <div class="cart-item__top row">
      <div class="cart-item__top-list">
        <div class="cart-item__top-item">
          <p>
            NDS:
          </p>
          <span class="nds">
            <b>{{item.ndc}}</b>
          </span>
        </div>
        <div class="cart-item__top-item">
          <p>
            Product name:
          </p>
          <span>
            {{item.product_name}}
          </span>
        </div>
        <div class="cart-item__top-item">
          <p>
            Pack Quantity:
          </p>
          <span>
            {{item.pack_quantity}}
          </span>
        </div>
        <div class="cart-item__top-item">
          <p>
            Strength:
          </p>
          <span>
            {{item.strength}}
          </span>
        </div>
        <div class="cart-item__top-item">
          <p>
            Pack Size:
          </p>
          <span>
            {{item.original_pack_size}}
          </span>
        </div>
        <div class="cart-item__top-item">
          <p>
            Dosage:
          </p>
          <span>
            {{item.dosage_form}}
          </span>
        </div>
        <div class="cart-item__top-item">
          <p>
            MFR:
          </p>
          <span>
            {{item.manufacturer_name}}
          </span>
        </div>
        <div class="cart-item__top-item">
          <p>
            Exp Date:
          </p>
          <span>
            {{item.expiration_date}}
          </span>
        </div>
        <div class="cart-item__top-item">
          <p>
            Packege Condition
          </p>
          <span>
            {{item.package_condition_id.title}}
          </span>
        </div>
        <div class="cart-item__top-item">
          <p>
            Price
          </p>
          <span>
            ${{item.price}}
          </span>
        </div>
        <div class="cart-item__top-item">
          <p>
            LOT#
          </p>
          <span>
            {{item.lot}}
          </span>
        </div>
        <div class="cart-item__top-item">
          <p>
            QTY
            Avail
          </p>
          <span>
            {{item.qty_avail}}
          </span>
        </div>
        <div class="cart-item__top-item">
          <p>
            Quantity:
          </p>
          <span>

            <div class="cart-item__item-count">
              <input type="text" v-model="item.quantity" v-mask="'###'" @blur="validQuantity(item)">
              <div class="cart-item__item-match">
                <div class="cart-item__item-plus" >
                  <inline-svg :src="require(`@/assets/images/svg/arrow-input-top.svg`)" @click="plusProductCount(item)"></inline-svg>
                </div>
                <div class="cart-item__item-minus" >
                  <inline-svg :src="require(`@/assets/images/svg/arrow-input-down.svg`)" @click="minusProductCount(item)"></inline-svg>
                </div>
              </div>
            </div>
          </span>
        </div>
        <div class="cart-item__top-item">
          <p>
            Total:
          </p>
          <span>
            <b>$ {{item.price * item.quantity}}</b>
          </span>
        </div>
        <div class="cart-item__top-item">
          <p>
            Action:
          </p>
          <span class="cart-item__remove-item"
            @click="sendRemoveCartItem(item)"
          >
            <inline-svg :src="require(`@/assets/images/svg/close-ic.svg`)"/>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions} from "vuex";

export default {
  props:['item'],
  methods:{
    sendRemoveCartItem(item){
      this.removeCartItem({id: item.id}).then(res=>{
        console.log('status - ',res.status);
        if(res.status){
          console.log('status 1');
          this.$toasted.success('Product success remove', {
            duration: 3000,
          })
          this.getCartProducts();
        }else{
          this.$toasted.error(JSON.stringify(res.data), {
            duration: 3000,
          })
        }
      })
    },
    plusProductCount(item) {
      if(item.quantity + 1 <= item.quantity_in_stock){
        item.quantity++;
        this.$forceUpdate();
      }
    },
    minusProductCount(item) {
      if(item.quantity - 1 >= 1){
        item.quantity--;
        this.$forceUpdate();
      }
    },
    ...mapActions({
      removeCartItem: 'product/removeCartItem',
      getCartProducts: 'product/getCartProducts',
    }),
    validQuantity(item) {
      if(item.quantity > item.quantity_in_stock){
        item.quantity = item.quantity_in_stock;
        this.$forceUpdate();
      }else if(item.quantity < 1){
        item.quantity = 1;
      }
    },
  }

}
</script>
<style lang="scss" scoped>
@import "src/assets/styles/styles";
.cart-item{
  border-radius: 27px;
  background-color: $white-grey;
  box-shadow: 0 0 6px 2px rgba(0,0,0,0.3);
  &__top{
    position: relative;
    padding:20px 20px 15px 20px;
    p{
      font-size: 14px;
      font-family: 'Inter', Arial;
      font-weight: 400;
      color: $grey;
      opacity: 0.5;
      margin-bottom: 10px;
      min-height: 40px;
    }
    span{
      font-size: 14px;
      font-family: 'Inter', Arial;
      font-weight: 400;
      color: $grey;
      &.nds{
        word-break: break-word;
      }
      &.cart-item__number{
        font-size: 18px;
        font-weight: 700;
      }
      &.cart-item__price{
        font-size: 24px;
        font-weight: 700;
      }
      &.cart-item__status{
        font-size: 16px;
        font-weight: 500;
        display: inline-block;
        padding: 3.5px 20px 5px;
        border-radius: 19px;
        background-color: $grey;
        color: $white;
        min-width: 120px;
        text-align: center;
        &.black{
          background-color: $grey;

        }
        &.red{
          background-color: $red;
        }
        &.green{
          background-color: $green;
        }
      }
    }
  }
  &__list{
    display: flex;
    flex-direction: column;
    margin-top: 15px;
  }
  &__list-item{
    display: flex;
    font-family: 'Inter', Arial;
    font-size: 14px;
    font-weight: 400;
    color: $grey;
    p{
      width: 50%;
    }
    span{
      width: 50%;
    }
  }
  &__items{
    background-color: white;
    transition: 0.3s all;
    padding: 20px 30px 40px;
    box-shadow: 0px 0px 11px rgba(59, 64, 240, 0.1);
    border-radius: 0 0 27px 27px;
    .item-block{
      margin: 0 -10px 0 -10px;
      &__title{
        font-size: 16px;
      }
      &__info{
        margin-bottom: 25px;
      }
      &__info-item{
        padding-bottom: 3px;
        p,span{
          font-size: 13px;
        }
      }
    }
  }
  &__discount{
    font-family: 'Inter', Arial;
    font-weight: 900;
    font-size: 28px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    span{
      padding: 15px 30px;
      background-color: $yellow;
      color: white;
      border-radius: 24px;
    }
  }
  &__info{
    padding: 10px 15px 20px 40px;
  }
  &__top-list{
    display: flex;
    position: relative;
    justify-content: space-between;
    @media screen and (max-width: 992px){
      flex-wrap: wrap;
    }
  }
  &__top-item{
    padding: 0 7px;
    user-select: none;
    @media screen and (max-width: 992px){
      margin-bottom: 15px;
    }
  }
  &__item-count{
    height: 48px;
    width: 65px;
    background-color: $white-grey;
    border: 1px solid rgba(223, 223, 223, 0.5);
    box-sizing: border-box;
    border-radius: 13px;
    position: relative;
    input{
      width: 100%;
      height: 100%;
      background-color: transparent;
      font-family: 'Inter', Arial;
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      color: $grey;
      line-height: 21px;
      opacity: 0.5;
      padding: 0 10px;
    }
  }
  &__item-left{
    align-self: flex-start;
  }
  &__product-price{
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

  }
  &__item-price{
    font-size: 26px;
    font-family: 'Inter', Arial;
    font-weight: 600;
  }
  &__item-match{
    position: absolute;
    right: 10px;
    top: 7px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }
  &__item-plus,
  &__item-minus{
    height: 12px;
    opacity: 0.6;
    &:active{
      transform: scale(0.8);
    }
    &:hover{
      opacity: 1;
    }
  }
  &__item-bottom{
    margin-bottom: 15px;
  }
  &__remove-item{
    cursor: pointer;
    svg{
      height: 25px;
    }
  }
}
</style>
